import React, {useCallback, useContext, useLayoutEffect, useState} from "react";
import "./index.css";
import 'react-tooltip/dist/react-tooltip.css'
import undo from "../../assets/icons/undo.svg";
import {KeycloakContext} from "../../context/KeycloakContext";
import ModalBlacklistAdd from "../ModalBlacklistAdd";
import {Tooltip} from "react-tooltip";

const CompanyBlacklist: React.FC = () => {
    const {accessToken} = useContext(KeycloakContext);
    const host = process.env.REACT_APP_API_URL;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [companies, setCompanies] = useState<{ id: number, name: string }[]>([]);
    const [newCompany, setNewCompany] = useState<string>("");

    const getCompanies = useCallback(async (search: string) => {
        try {
            const response = await fetch(`${host}/blacklisted-companies?search=${encodeURIComponent(search)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCompanies(data ?? []); // Упрощенная проверка на null/undefined
        } catch (error) {
            console.error(`There was a problem with the fetch operation: ${error}`);
        }
    }, [accessToken, host]);

    const deleteCompany = useCallback(async (id: number, name: string) => {
        try {
            const response = await fetch(`${host}/blacklisted-companies/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Обновляем список компаний локально
            setCompanies((prevCompanies) => prevCompanies.filter((company) => company.name !== name));
        } catch (error) {
            console.error(`There was a problem with the fetch operation: ${error}`);
        }
    }, [accessToken, host]);


    const debounce = <T extends (...args: any[]) => any>(func: T, wait: number): ((...args: Parameters<T>) => void) => {
        let timeout: ReturnType<typeof setTimeout> | null = null;
        return function (...args: Parameters<T>) {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetCompanies = useCallback(debounce(getCompanies, 1000), [getCompanies]);


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        setNewCompany(searchValue);
        debouncedGetCompanies(searchValue);
    };

    const removeCompany = useCallback((id: number, name: string) => {
        deleteCompany(id, name);
    }, [deleteCompany]);

    const handleCancel = useCallback(() => {
        setIsModalOpen(false);
        getCompanies('');
    }, [getCompanies]);

    useLayoutEffect(() => {
        getCompanies('');
    }, [getCompanies]);

    return (
        <div className="blacklist-container">
            <ModalBlacklistAdd isOpen={isModalOpen} onClose={handleCancel}/>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search by company name"
                    className="search-input"
                    value={newCompany}
                    onChange={handleSearchChange}
                />
                <button className="add-button" onClick={() => setIsModalOpen(true)}>
                    Add company to black list +
                </button>
            </div>
            <table className="blacklist-table">
                <thead>
                <tr>
                    <th style={{borderRight: '0px'}}>Company name</th>
                    <th style={{borderLeft: '0px'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {companies.length === 0 ? (
                    <tr>
                        <td colSpan={2} className="no-companies">
                            No result
                        </td>
                    </tr>
                ) : (
                    companies.map((company) => (
                        <tr key={company.id}>
                            <td style={{borderRight: '0px'}}>{company.name}</td>
                            <td style={{width: '150px', borderLeft: '0px'}}>
                                <div
                                    style={{marginLeft: '10px', justifyContent: 'center', cursor: 'pointer'}}
                                    onClick={() => removeCompany(company.id, company.name)}
                                >
                                    <img style={{width: "24px"}} data-tooltip-id="tooltip" data-tooltip-place={'bottom'}
                                         data-tooltip-content={'Unblock company'} src={undo}
                                         alt="remove from blocklist"/>
                                    <Tooltip id={'tooltip'} border='1px solid lightgray'
                                             style={{background: 'white', color: 'black'}}/>
                                </div>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    );
};

export default CompanyBlacklist;