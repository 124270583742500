import React from 'react';
import './index.css';

interface ModalProps {
    companyName: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ModalBlacklistConfirm: React.FC<ModalProps> = ({companyName, isOpen, onClose, onConfirm}) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p className="regular-23">Add company "{companyName}" to blacklist?</p>
                <p className="regular-14">Are you sure you want to continue?</p>
                <p className="regular-14">If you proceed, all the currently found results related to this company will
                    be removed and the
                    system will no longer search for company relevant prospects.</p>
                <div className="modal-actions">
                    <button className="cancel-button" onClick={onClose}>Cancel</button>
                    <button className="confirm-button" onClick={onConfirm}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default ModalBlacklistConfirm;