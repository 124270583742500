import "./allResultsListItem.css";
import 'react-tooltip/dist/react-tooltip.css'
import {Link} from "react-router-dom";
import shareSVG from "../../assets/icons/share.png";
import cancel from "../../assets/icons/cancel.svg";
import React, {useContext, useState} from "react";
import {KeycloakContext} from "../../context/KeycloakContext";
import ModalBlacklistConfirm from "../ModalBlacklistConfirm";
import {Tooltip} from "react-tooltip";

export type Vacancy = {
    id: number;
    position: string;
    searchQuery: string;
    company: string;
    location: string;
    link: string;
    publishedAt: string;
};

export type AllResultsListProps = {} & Vacancy;

function AllResultsListItem({
                                position,
                                searchQuery,
                                location,
                                company,
                                publishedAt,
                                link,
                            }: AllResultsListProps) {
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    const {accessToken} = useContext(KeycloakContext);
    const host = process.env.REACT_APP_API_URL
    const params = new URLSearchParams(searchQuery);
    const query = Object.fromEntries(params);
    const searchRequest = `${query.keyword}${
        query.jobType ? `, ${query.jobType}` : ""
    }${query.grade ? `, ${query.grade}` : ""}`;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<string>('');

    const handleAddCompany = (companyName: string) => {
        setSelectedCompany(companyName);
        setIsModalOpen(true);
    };

    const handleConfirm = () => {
        // Логика добавления компании в черный список
        addToBlocklist([selectedCompany])
        setIsModalOpen(false);
        setSelectedCompany('');
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedCompany('');
    };

    const getSourceFromLink = (link: string) => {
        if (link.includes("linkedin")) {
            return "LinkedIn";
        }
        if (link.includes("indeed")) {
            return "Indeed";
        }
        if (link.includes("welcometothejungle")) {
            return "Welcome to the Jungle";
        }
        return "External link";
    };

    const addToBlocklist = async (companies: string[]) => {
        try {
            const response = await fetch(`${host}/blacklisted-companies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(companies)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            if (response.ok) {
                alert(`Company ${companies[0]} has been successfully blacklisted. \n` +
                    `You can remove company from the black list in Admin settings. `);
            }

        } catch (error) {
            alert(`There was a problem with the fetch operation: ${error}`);
        }
    };

    return (
        <tr className="ar-list-item">
            <td
                style={{
                    width: "200px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <span>{position}</span>
                <Link to={link} target="_blank">
                    <img style={{width: "24px"}} src={shareSVG} alt="share"/>
                </Link>
            </td>
            <td style={{width: "200px"}}>{searchRequest}</td>
            <td style={{width: "140px"}}>{location}</td>
            <td style={{width: "170px", height: '24px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        {company}
                    </div>
                    <div style={{marginLeft: '10px', float: 'right', cursor: 'pointer'}}
                         onClick={() => handleAddCompany(company)}>
                        <img style={{width: "24px"}} data-tooltip-id="tooltip" data-tooltip-place={'bottom'}
                             data-tooltip-content={'Block company'} src={cancel} alt="blocklist"/>
                        <Tooltip id={'tooltip'} border='1px solid lightgray'
                                 style={{background: 'white', color: 'black'}}/>
                    </div>
                    <ModalBlacklistConfirm
                        companyName={selectedCompany || ''}
                        isOpen={isModalOpen}
                        onClose={handleCancel}
                        onConfirm={handleConfirm}
                    />
                </div>
            </td>
            <td style={{width: "140px"}}>
                {new Date(publishedAt).toLocaleDateString("en-US", options)}
            </td>
            <td style={{width: "120px"}}>{getSourceFromLink(link)}</td>
        </tr>
    );
}

export default AllResultsListItem;
