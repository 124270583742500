import React, {useContext, useLayoutEffect, useState} from "react";
import "./index.scss";
import close from "../../assets/icons/close.svg";
import check from "../../assets/icons/check.svg";
import edit from "../../assets/icons/edit.svg";
import splash from "../../assets/icons/splash.svg";
import {KeycloakContext} from "../../context/KeycloakContext";
interface ResponseData {
    link: string;
}

type InputState = 'empty' | 'editing' | 'filled' | 'error';

const UrlInputComponent: React.FC = () => {
    const {accessToken} = useContext(KeycloakContext);
    const host = process.env.REACT_APP_API_URL


    const [url, setUrl] = useState<string>("");
    const [inputState, setInputState] = useState<InputState>('empty');
    const [isValid, setIsValid] = useState<boolean>(false);
    const fetchData = async () => {
        try {
            const response = await fetch(`${host}/files`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: ResponseData = await response.json();
            data.link != null || undefined ? setUrl(data.link) : setUrl("");
            data.link != null || undefined ? setInputState("filled") : setInputState("empty")
        } catch (error) {
            setInputState('editing')
            alert(`There was a problem with the fetch operation: ${error}`);
        }
    };
    useLayoutEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const validateUrl = (value: string): boolean => {
        const urlPattern = /^https:\/\/docs\.google\.com\/spreadsheets(\/.*)?$/;
        return urlPattern.test(value);
    };



    const putData = async (url: string) => {
        try {
            const response = await fetch(`${host}/files`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    link: url
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: ResponseData = await response.json();
            console.log('Response data:', data);
        } catch (error) {
            setInputState('editing')
            alert(`There was a problem with the fetch operation: ${error}`);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputState('editing');
        setUrl(value);
        if (value === '') {
            setInputState('empty');
        } else if (validateUrl(value)) {
            setIsValid(true);
        } else {
            setInputState('editing');
        }
    };

    const handleAccept = () => {
        if (isValid) {
            setInputState('filled');
            putData(url);
        } else {
            setUrl("");
            setUrl('Wrong input')
            setInputState('error');
        }
    };

    const handleEdit = () => {
        setInputState('editing')
    };


    const handleReject = () => {
        setUrl('');
        setInputState('empty')
        setIsValid(false);
    };


    return (
        <div>
            <p className="semibold-13"
               style={inputState === 'error' ? {color: "red"} : {color: '#444444'}}>URL to company list
                file:</p>
            <div style={{width: '10px', height: '10px'}}></div>
            <div className="input-with-buttons"
                 style={inputState === 'error' ? {borderColor: "red"} : inputState === 'filled' ? {borderColor: 'transparent'} : inputState === 'empty' ? {borderColor: '#999999'} : {borderColor: 'black'}}>
                {inputState !== "filled" ? (
                    <input
                        type="text"
                        value={url}
                        onChange={handleChange}
                        placeholder="Enter link to file"
                        className="url-input"
                    />
                ) : <p className="url-input"
                       style={{
                           color: '#428BCA',
                           width: '300px',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis'
                       }}>{`${url}`}</p>}
                {inputState === 'editing' && (
                    <>
                        <img src={splash} alt={"SplashIcon"}/>
                        <button onClick={handleAccept} className="button-left">
                            <img src={check} alt={"CheckIcon"}/>
                        </button>
                        <button onClick={handleReject} className="button-right">
                            <img src={close} alt={"CloseIcon"}/>
                        </button>
                    </>
                )}
                {inputState === 'filled' && (
                    <>
                        <button onClick={handleEdit} className="button-left">
                            <img src={edit} alt={"EditIcon"}/>
                        </button>
                    </>
                )}
                {inputState === 'error' && (
                    <>
                        <button onClick={handleReject} className="button-right">
                            <img src={close} alt={"CloseIcon"}/>
                        </button>
                    </>
                )}
            </div>
            {inputState === 'error' && (
                <div style={{color: 'red', marginTop: '5px', fontSize: '12px'}}>
                    You can only enter data as a URL link (example: http://www.filelocation.com)
                </div>
            )}
        </div>
    );
};

export default UrlInputComponent;