import React, {useCallback, useContext, useLayoutEffect, useState} from 'react';
import './index.css';
import MultiSelect from "../MultiSelect";
import {KeycloakContext} from "../../context/KeycloakContext";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface OptionType {
    value: string;
    label: string;
}

interface FormState {
    companies: OptionType[];
}

const formInitialState: FormState = {
    companies: [],
};

const ModalBlacklistConfirm: React.FC<ModalProps> = ({isOpen, onClose}) => {
    const {accessToken} = useContext(KeycloakContext);
    const host = process.env.REACT_APP_API_URL;

    const [companies, setCompanies] = useState<OptionType[]>([]); // Список доступных компаний для выбора
    const [value, setValue] = useState<FormState>(formInitialState); // Выбранные компании


    const handleSelectChange = (selectedOptions: OptionType[] | null) => {
        setValue({
            ...value,
            companies: selectedOptions || [], // Если ничего не выбрано, передаем пустой массив
        });
    };

    // Получение списка компаний с сервера
    const getCompanies = useCallback(async () => {
        try {
            const response = await fetch(`${host}/vacancies/companies`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data: string[] = await response.json(); // Ожидаем массив строк
            if (Array.isArray(data)) {
                const transformedCompanies = data.map((item) => ({
                    label: item,
                    value: item,
                }));
                setCompanies(transformedCompanies);
            } else {
                console.error('Unexpected data format:', data);
            }
        } catch (error) {
            console.error(`There was a problem with the fetch operation: ${error}`);
        }
    }, [accessToken, host]);

    useLayoutEffect(() => {
        getCompanies();
    }, [getCompanies]);


    const addToBlocklist = async () => {
        const companyValues = value.companies;
        try {
            const response = await fetch(`${host}/blacklisted-companies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(companyValues) // Отправляем массив строк
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            alert(`Companies have been successfully added to the blacklist.`);
            onClose(); // Закрываем модальное окно после успешного добавления
        } catch (error) {
            alert(`There was a problem with the fetch operation: ${error}`);
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p className="regular-23">Add company to blacklist?</p>
                <p className="regular-15">Company</p>
                <MultiSelect
                    name="Companies"
                    label=""
                    //@ts-ignore
                    value={value.companies}
                    options={companies}
                    //@ts-ignore
                    onChange={handleSelectChange}
                    placeholder="You can select multiple companies"
                />
                <div className="modal-actions">
                    <button className="cancel-button" onClick={onClose}>Cancel</button>
                    <button className="confirm-button" disabled={value.companies.length === 0} onClick={addToBlocklist}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default ModalBlacklistConfirm;
