import React from 'react';
import UrlInputComponent from "../../components/UrlInputComponent";
import './index.scss'
import CompanyBlacklist from "../../components/CompanyBlackList";

const AdminPanel: React.FC = () => {
    return (
        <div className="app">
            <p className="regular-22">Company file</p>
            <div className="output-container">
                <UrlInputComponent/>
            </div>
            <p className="regular-22">Company black list</p>
            <div className="input-container">
                <CompanyBlacklist/>
            </div>
            <div style={{height: '74px'}}>
            </div>
        </div>
    );
};

export default AdminPanel;