import {createContext, ReactNode, useEffect, useState} from "react";
import Keycloak from "keycloak-js";
import {jwtDecode} from "jwt-decode";

type KeycloakContextType = {
    accessToken: string | null;
    keycloak: Keycloak | null;
    userName: string | null;
    userEmail: string | null;
};

export const KeycloakContext = createContext<KeycloakContextType>({
    accessToken: null,
    keycloak: null,
    userName: null,
    userEmail: null,
});

type KeycloakContextProps = {
    children: ReactNode;
};

export const KeycloakProvider: React.FC<KeycloakContextProps> = ({children}) => {
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
    const [userName, setUserName] = useState<string | null>(null);
    const [userEmail, setUserEmail] = useState<string | null>(null);

    useEffect(() => {
        const keycloak = new Keycloak({
            url: process.env.REACT_APP_KEYCLOAK_URL,
            realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
        });

        keycloak
            .init({
                onLoad: "login-required",
                checkLoginIframe: true,
                pkceMethod: "S256",
            })
            .then((auth) => {
                if (!auth) {
                    console.error("Authentication failed");
                } else {
                    console.info("Authenticated");

                    keycloak.onTokenExpired = () => {
                        keycloak.updateToken(30)
                            .then((refreshed) => {
                                if (refreshed) {
                                    console.info("Token refreshed");
                                    setAccessToken(keycloak.token as string);
                                } else {
                                    console.warn("Token not refreshed, valid for more than 30 seconds");
                                }
                            })
                            .catch(() => {
                                console.error("Failed to refresh token");
                            });
                    };

                    if (keycloak.token) {
                        setAccessToken(keycloak.token);
                        setKeycloak(keycloak);

                        const decodedToken: any = jwtDecode(keycloak.token as string);
                        setUserName(decodedToken.name);
                        setUserEmail(decodedToken.email);
                    }
                }
            })
            .catch((reason) => {
                console.error("Authentication Failed", reason);
            });
    }, []);

    useEffect(() => {
        if (keycloak) {
            const interval = setInterval(() => {
                keycloak.updateToken(30)
                    .then((refreshed) => {
                        if (refreshed) {
                            console.info("Token refreshed");
                            setAccessToken(keycloak.token as string);
                        } else {
                            console.warn("Token not refreshed, valid for more than 30 seconds");
                        }
                    })
                    .catch(() => {
                        console.error("Failed to refresh token");
                    });
            }, 600000); // Обновлять токен каждые 10 минуту

            return () => clearInterval(interval);
        }
    }, [keycloak]);

    return (
        <KeycloakContext.Provider
            value={{
                accessToken: accessToken,
                keycloak: keycloak,
                userName: userName,
                userEmail: userEmail,
            }}
        >
            {children}
        </KeycloakContext.Provider>
    );
};